export default {
    data() {
        return {
            isLoading: false,
            response: [],
            stateLoaded: false,
        }
    },
    created() {
        // setTimeout(() => {
            this.stateLoaded = true;
            this.getData();
        // }, 500);
    },
    computed: {
        activeUser() {
            console.log('my', this.$store.getters['auth/getAuthenticatedUser']);
            return this.$store.getters['auth/getAuthenticatedUser']
        },
        isUserApproved() {
            if (this.stateLoaded) {
                return this.activeUser.member_status === 'approved'
            }
        },
        myEvents() {
            let events = [];
            this.response.forEach(element => {
                const found = this.activeUser.my_attending_events.find((item) => item.occurence_id === element.occurence_id);
                if(found){
                    events.push(element);
                }
            });
            return events;
        }
    },
    methods: {
        getData() {
            this.isLoading = true
            let url = this.isUserApproved
                ? '/experiences'
                : '/experience-master-list'
            this.$http
                .get(url)
                .then((response) => {
                    if (response.status == 200) {
                        this.response = this.isUserApproved
                            ? response.data.response_data
                            : response.data.response_data.experiences;
                        this.isLoading = false;
                        console.log(this.response);
                    }
                })
                .catch((err) => {
                    console.error(err)
                })
        },
        goToEventDetail(eventObj) {
            this.$router.push({
                name: `experiences_details`,
                params: {
                    type: eventObj.type,
                    id: eventObj.id,
                    event_data: eventObj,
                },
            })
        },
    }
}